.paginated-grid {
  .paginated-grid__pagination_per_page {
    justify-content: flex-start;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .form-label {
    margin-bottom: 0;
  }

  .form-control {
    display: inline-block;
    width: auto;
  }

  @include media-breakpoint-up(md) {
    .paginated-grid__pagination_per_page {
      justify-content: flex-end;
    }
  }

  .paginated-grid__top-pagination {
    justify-content: space-between !important;
  }

  .paginated-grid__grid {
    @extend %grid-shadow;

    .filter-box {
      max-width: 300px;
    }

    .double-line {
      display: flex;
      flex-flow: wrap;
      line-height: 1.5 !important;
      align-items: center;
      height: 100%;
    }

    .double-line > div {
      width: 100%;
    }

    .ag-row .ag-react-container {
      height: 100%;
    }

    .btn {
      padding: 0.2rem 0.75rem;
    }
  }
}
