%odd-row {
  background: rgba(0, 0, 0, 0.03);
}

%blue-top-border {
  border-top: 4px solid $primary-20;
}

%navy-top-border {
  border-top: 4px solid $secondary;
}
