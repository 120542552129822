.homepage {
  h1,
  h5 {
    font-weight: bold;
    text-align: center;
  }

  img {
    max-width: 100%;
  }

  .homepage__hero {
    background-position: center center;
    background-size: cover;
    height: 65vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 4rem 2rem 4rem 0;

    .homepage__hero__text {
      flex-grow: 1;
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 3rem;
    }

    img {
      max-width: 75px;
      margin-bottom: 0.5rem;
    }

    h5 {
      font-weight: normal;
    }
  }

  .homepage__section {
    overflow: hidden;
    padding: $spacer * 3 0;
  }

  h3 {
    font-weight: bold;
  }
}

.background-light-blue {
  background-color: $container-blue;
}

.homepage__section__img-left {
  position: relative;
}

.homepage__section__img-right {
  position: relative;
}

@include media-breakpoint-up(xl) {
  .homepage__hero {
    height: 55vh;
  }
}

@include media-breakpoint-up(lg) {
  .homepage__hero {
    max-width: 2000px;
  }

  .homepage__section__img-left {
    right: 12%;
    padding: 0;
  }

  .homepage__section__img-right {
    left: 12%;
    padding: 0;
  }
}

@include media-breakpoint-up(md) {
  .homepage__hero {
    background: url('../../../assets/images/hero_genomics-platform.png');
  }

  .homepage {
    .homepage__hero {
      .homepage__hero__text {
        width: 50%;
      }
    }
    .homepage__section__text-right {
      padding-left: 0;
    }

    .homepage__section__text-left {
      padding-right: 0;
    }
  }
}

@include media-breakpoint-down(lg) {
  .homepage__section__img-left {
    right: 10%;
  }

  .homepage__section__img-right {
    left: 10%;
  }
}

@include media-breakpoint-down(md) {
  .homepage__hero {
    background: url('../../../assets/images/hero_genomics-platform-sm.png');
    margin-bottom: 2rem;
  }

  .jumbotron {
    padding: 1rem 0.5rem;
  }

  h1 {
    font-size: 35px;
  }

  h5 {
    font-size: 20px;
  }

  .homepage__section__img-left {
    display: none;
  }

  .homepage__section__img-right {
    display: none;
  }
}
