.tool-card {
  box-sizing: content-box;
  height: 175px;
  width: 500px;
  text-align: left;
  overflow: hidden;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 11px -3px #000;

  .card-title {
    font-weight: 700;
    color: $secondary;
    margin-bottom: 0;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    margin: 0 15px;
  }

  .card-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    line-height: 1.25rem;
    margin-bottom: 2px;
  }

  .tool-icon-container {
    display: flex;
    max-height: 100%;
    justify-content: center;
    align-items: center;
  }
}
