html,
body,
main#app,
main#app .page,
.page .page-children {
  height: 100%;
}

main#app {
  .page {
    display: flex;
    flex-direction: column;

    .main-content {
      flex: 1 0 auto;
    }
  }
}

.footer {
  z-index: 2;
}

// shortens and centers full-page template information for the wizard
.page > .access-request-submission-wizard {
  max-width: 1000px;
  align-self: center;
}
