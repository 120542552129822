.spinner-wrapper {
  margin: auto;

  .sk-spinner > div {
    //color: black; // Changes the color of the dots in the spinner.
  }

  &.spinner--styled {
    height: 100%;
    margin: auto;

    margin-top: 40px;
    animation-duration: 0.5ms;
    width: 100px;
    height: 100px;
  }

  &.spinner--inline {
    display: inline-block;

    margin-top: 0px;
    animation-duration: 0ms;
    width: 14px;
    height: 14px;
  }
}
