.badge.tag-primary {
  background-color: $blue-50;
  color: $primary-standard;
}

.badge.tag-secondary {
  background-color: $gray-200;
  color: $gray-800;
}

.badge.tag-success {
  background-color: $green-50;
  color: $green-400;
}

.badge.tag-danger {
  background-color: $red-50;
  color: $red-600;
}

.badge.tag-warning {
  background-color: $amber-50;
  color: $yellow-800;
}

.badge.tag-error {
  background-color: $deepOrange-50;
  color: $deepOrange-600;
}
