@mixin card($enable-hover: false, $enable-shadow: true) {
  @extend %blue-top-border;
  display: flex;
  font-weight: 500;

  @if $enable-shadow {
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
  }
  @if $enable-hover {
    transition: all 0.25s ease-in-out;
    margin-top: 2.7rem;

    &:hover {
      margin-top: 2.5rem;
      margin-bottom: 0.2rem;

      @if $enable-shadow {
        box-shadow: 0px 15px 25px 2px rgba(0, 0, 0, 0.15), 0px 6px 30px 5px rgba(0, 0, 0, 0.15),
          0px 10px 10px -6px rgba(0, 0, 0, 0.3);
      }
    }
  }
}
