.login-page {
  .login-help-text {
    font-size: 14px;
    text-align: center;
  }

  .row_login-cards {
    justify-content: center;
  }
}
