.tool-image {
  align-items: center;
  background-image: url('../../../assets/images/workflow-card-background.png');
  background-position: -12px -19px;
  background-repeat: no-repeat;
  background-size: 130%;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.tool-image--bullet {
  background-position: -50px -10px;
  background-size: 160%;
  border-bottom-right-radius: 125px;
  border-top-right-radius: 125px;
  height: 120%;

  .card-img {
    margin-left: 0.5rem;
    width: 83%;
  }
}
