.sidebar-filters {
  margin-top: -2px;

  .sidebar-filters-title {
    font-weight: 600;
    margin-top: 2px;
    padding: 5px 0px 5px 15px;
    background-color: #ddd;
    font-size: 14px;
    border-top: 3px solid white;
  }

  .sidebar-filters-subfilter {
    margin-bottom: 3px;
    margin-top: 0px;
    font-size: 13px;
    padding: 5px 0px 5px 15px;
    border-bottom: 3px solid white;
  }

  .sidebar-filters-subfilter-title {
    font-weight: 600;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: pointer;
  }

  .fa-chevron-down {
    margin-left: 5px;
    vertical-align: 10%;
    margin-right: 10px;
    float: right;
  }

  .rotate {
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  .rotate.down {
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .sidebar-filters-buttons {
    /* max-height: none; */
    transition: all 0.2s ease-out;
  }
}
