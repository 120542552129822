.tool-list-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;

  .bare-list {
    display: flex;
    flex-wrap: wrap;
  }
}
