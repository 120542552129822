.login-card {
  @include card;
  @extend %navy-top-border;

  margin: 2rem .5rem;
  padding: 2rem 1rem;

  .login-card__logo {
    max-height: 75px;
    margin-left: 1rem;
  }

  .login-card__info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: center;
    flex-grow: 1;
  }

  .login-card__title, .login-card__subtext {
    color: $secondary;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .login-card__title {
    max-width: 300px;
    margin-left: 1rem;
  }

  .login-card__subtext {
    margin-top: 1rem;
    font-size: 80%;
  }

  button {
    width: 175px;
  }
}
