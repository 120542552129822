.homepage__panel {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $spacer * 1.5;
  position: relative;

  p {
    flex-grow: 1;
  }

  img {
    background-color: $secondary;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}

.homepage__middle-panel::before {
  content: '';
  position: absolute;
  background-color: $min-black;
  width: 1px;
  height: 90%;
  bottom: 10px;
  left: 0px;
}

.homepage__middle-panel::after {
  content: '';
  position: absolute;
  background-color: $min-black;
  width: 1px;
  height: 90%;
  bottom: 10px;
  right: 0px;
}

@include media-breakpoint-down(lg) {
  .homepage__panel {
    margin: 1rem 0;
  }

  .homepage__middle-panel::before {
    content: none !important;
  }

  .homepage__middle-panel::after {
    content: none !important;
  }
}
