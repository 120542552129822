.addendum-page {
  .left-section {
    max-width: 600px;
  }

  h2,
  h3,
  h4,
  h5 {
    font-weight: 200;
  }

  .heading-icon {
    color: $primary-standard;
  }

  .addendum-subsection {
    margin: 2.5rem 0;

    .addendum-subsection-title {
      i {
        color: $gray-500;
        font-size: 15px;
      }
    }

    .edit-user-detail {
      border: 1px solid $blue-50;
      border-radius: 8px;
    }

    .user-detail {
      padding: 0px 10px;

      .text-small {
        font-size: 13px;
      }
      .edit-icon {
        color: $primary-standard;
        display: none;
      }
      &:hover {
        border-radius: 6px;
      }
    }

    .user-detail:hover {
      background-color: $gray-100;
    }

    .user-detail.editable:hover {
      background-color: $blue-50;
      cursor: pointer;
      .edit-icon {
        display: block;
      }
    }

    .add-additional-applicants {
      min-height: 150px;
      border: 1px solid $blue-50;
      border-radius: 6px;
      background-color: $blue-50;

      .fa-user {
        color: $blue-200;
      }
    }
  }

  .dropzone-form {
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    .right-section {
      margin: 3rem !important;
    }

    .left-section {
      margin: 0 3rem !important;
      padding: 0 !important;
    }

    .download-addendum-form {
      font-size: 13px;
    }

    .vr {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    .submit-cancel-buttons {
      flex-direction: column-reverse !important;
      width: 100% !important;

      .cancel-button,
      .upload-button {
        margin: 5px 0 !important;
      }

      button {
        width: 100%;
      }
    }
  }
}
