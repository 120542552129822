.dropzone-form-upload {
  //...vars.layout.dropzone(); //FIXME: SASS variable/mixin
  width: 100%;
  min-height: 148px;
  border-width: 1px;
  border-color: $blue-50;
  border-style: solid;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  transition: all 300ms ease-in-out;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  &:focus {
    outline: none;
  }

  .upload-icon {
    color: #002044;
    font-size: 22px;
  }

  .link-like {
    color: $primary-standard;
  }
}
.dropzone-form-upload--active {
  border-color: $primary;
  color: $primary;
}
