@use 'ag-grid-community/styles' as ag;
@include ag.grid-styles(
  (
    theme: material,
  )
);

// If we want to distribute our own customized FontAwesome, we can do so here,
// but currently we utilize `use.fontawesome.com` from within
// `components/templates/PageTemplate/Helmet.js`
// $fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
// @import '~@fortawesome/fontawesome-free/scss/fontawesome';
// @import '~@fortawesome/fontawesome-free/scss/regular';
// @import '~@fortawesome/fontawesome-free/scss/solid';
// @import '~@fortawesome/fontawesome-free/scss/brands';

$stjude-sans-font-path: '~@stjudecloud/assets/fonts/stjude-sans';
$stjude-images-path: '~@stjudecloud/assets/images';

@import '~@stjudecloud/theme-bootstrap/scss/stjude-cloud-theme';

@import './colors';
@import './mixins';

@import './components/access-request-submission-wizard';
@import './components/access-unit-badge';
@import './components/access-unit-jobs';
@import './components/admin-tool-list';
@import './components/admin-user-page';
@import './components/custom-switch';
@import './components/dashboard-page';
@import './components/data-browser-grid';
@import './components/data-browser-page';
@import './components/dropzone-form';
@import './components/dropzone-wrapper';
@import './components/electronic-data-access-request';
@import './components/filter-box';
@import './components/header';
@import './components/home-page';
@import './components/home-page-panel';
@import './components/login-card';
@import './components/login-page';
@import './components/markdown';
@import './components/page-content';
@import './components/page-sidebar-template';
@import './components/page-template';
@import './components/paginated-grid';
@import './components/previous-requests';
@import './components/sidebar-filters';
@import './components/spinner-wrapper';
@import './components/summary-table';
@import './components/tool-card';
@import './components/tool-editor';
@import './components/tool-image';
@import './components/tool-launcher-page';
@import './components/tool-list';
@import './components/validate';
@import './components/with-user-authentication';
@import './components/wizard_documentation_button';
@import './components/addendum-page';
@import './components/temp-badge';

@import './feature-box';

.link {
  @include link-like();
}

ul.no-bullets {
  list-style: none;
}

.ag-theme-material {
  font-family: $font-family-sans-serif !important;
}

// Make AgGrid header cells clickable on more than just actual text
.ag-cell-label-container {
  height: 100%;
}
.ag-checkbox-input-wrapper.ag-checked::after,
.ag-checkbox-input-wrapper.ag-indeterminate::after {
  color: $primary !important;
}

// Bring the top margin up for tooltips - this fixes tooltips on the first ag-grid row
// This should create enough space for tooltips up to 4 lines
.tooltip {
  margin-top: -120px;
}

.btn.btn-xs {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: $btn-border-radius;
}

.delayed-hide {
  animation: delayedHide 3s forwards;
}
@keyframes delayedHide {
  0% {
    opacity: 1;
  }
  83.4% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
