$black: #000;
$white: #fff;

$transparent: rgba(0, 0, 0, 0) !default;
$full-black: rgba(0, 0, 0, 1) !default;
$dark-black: rgba(0, 0, 0, 0.87) !default;
$light-black: rgba(0, 0, 0, 0.54) !default;
$min-black: rgba(0, 0, 0, 0.26) !default;
$faint-black: rgba(0, 0, 0, 0.12) !default;
$full-white: rgba(255, 255, 255, 1) !default;
$dark-white: rgba(255, 255, 255, 0.87) !default;
$light-white: rgba(255, 255, 255, 1) !default;

$primary-standard: $primary;
$primary-hover: scale-color($primary, $lightness: -7.5%);
$primary-active: scale-color($primary, $lightness: -10%);
$primary-disabled: scale-color($primary, $lightness: 40%);
$primary-110: scale-color($primary, $lightness: -10%);
$primary-100: $primary-standard;
$primary-90: scale-color($primary, $lightness: 90%);
$primary-85: scale-color($primary, $lightness: 85%);
$primary-80: scale-color($primary, $lightness: 80%);
$primary-70: scale-color($primary, $lightness: 70%);
$primary-60: scale-color($primary, $lightness: 60%);
$primary-50: scale-color($primary, $lightness: 50%);
$primary-40: scale-color($primary, $lightness: 40%);
$primary-30: scale-color($primary, $lightness: 30%);
$primary-20: scale-color($primary, $lightness: 20%);
$primary-10: scale-color($primary, $lightness: 10%);

$primaries: () !default;
$primaries: map-merge(
  (
    'standard': $primary-standard,
    'hover': $primary-hover,
    'active': $primary-active,
    'disabled': $primary-disabled,
    '110': $primary-110,
    '100': $primary-100,
    '90': $primary-90,
    '85': $primary-85,
    '80': $primary-80,
    '70': $primary-70,
    '60': $primary-60,
    '50': $primary-50,
    '40': $primary-40,
    '30': $primary-30,
    '20': $primary-20,
    '10': $primary-10,
  ),
  $primaries
);

$secondary-standard: #931638;
$secondary-hover: #a91940;
$secondary-active: #6b1129;
$secondary-disabled: #ba6a80;
$secondary-dark: #931338;
$secondary-light: #992948;
$secondary-60: #be7388;
$secondary-80: #a94560;
$secondary-100: #931638;
$secondary-120: #87122f;
$secondary-140: #7a0e26;
$secondary-180: #610615;

$secondaries: () !default;
$secondaries: map-merge(
  (
    'standard': $secondary-standard,
    'hover': $secondary-hover,
    'active': $secondary-active,
    'disabled': $secondary-disabled,
    'dark': $secondary-dark,
    'light': $secondary-light,
    '60': $secondary-60,
    '80': $secondary-80,
    '100': $secondary-100,
    '120': $secondary-120,
    '140': $secondary-140,
    '180': $secondary-180,
  ),
  $secondaries
);

$tertiary-standard: #edc951;
$tertiary-hover: #d8b74a;
$tertiary-active: #ad933b;
$tertiary-disabled: #f3dc90;

$tertiaries: () !default;
$tertiaries: map-merge(
  (
    'standard': $tertiary-standard,
    'hover': $tertiary-hover,
    'active': $tertiary-active,
    'disabled': $tertiary-disabled,
  ),
  $tertiaries
);

$accessory-green: #87a03c;
$accessory-orange: #d47f2b;
$accessory-turquoise: #00818b;
$accessory-yellow: #efb22d;
$accessory-purple: #664a78;
$accessory-lightYellow: #ffd87f;

$accessories: () !default;
$accessories: map-merge(
  (
    'green': $accessory-green,
    'orange': $accessory-orange,
    'turquoise': $accessory-turquoise,
    'yellow': $accessory-yellow,
    'purple': $accessory-purple,
    'lightYellow': $accessory-lightYellow,
  ),
  $accessories
);

$neutral-dark: #222222;
$neutral-medium-dark: #343434;
$neutral-medium: #828282;
$neutral-medium-light: #a7a7a7;
$neutral-light: #dfdfdf;

$neutrals: () !default;
$neutrals: map-merge(
  (
    'dark': $neutral-dark,
    'medium-dark': $neutral-medium-dark,
    'medium': $neutral-medium,
    'medium-light': $neutral-medium-light,
    'light': $neutral-light,
  ),
  $neutrals
);

$container-blue: scale-color($primary, $lightness: 93%);
$container-blueBorder: #457ea6;
$container-font: #0f4465;

$containers: () !default;
$containers: map-merge(
  (
    'blue': $container-blue,
    'blueBorder': $container-blueBorder,
    'font': $container-font,
  ),
  $containers
);

$pink-50: #fce4ec;
$pink-100: #f8bbd0;
$pink-200: #f48fb1;
$pink-300: #f06292;
$pink-400: #ec407a;
$pink-500: #e91e63;
$pink-600: #d81b60;
$pink-700: #c2185b;
$pink-800: #ad1457;
$pink-900: #880e4f;
$pink-A100: #ff80ab;
$pink-A200: #ff4081;
$pink-A400: #f50057;
$pink-A700: #c51162;

$pinks: () !default;
$pinks: map-merge(
  (
    '50': $pink-50,
    '100': $pink-100,
    '200': $pink-200,
    '300': $pink-300,
    '400': $pink-400,
    '500': $pink-500,
    '600': $pink-600,
    '700': $pink-700,
    '800': $pink-800,
    '900': $pink-900,
    'A100': $pink-A100,
    'A200': $pink-A200,
    'A400': $pink-A400,
    'A700': $pink-A700,
  ),
  $pinks
);

$purple-50: #f3e5f5;
$purple-100: #e1bee7;
$purple-200: #ce93d8;
$purple-300: #ba68c8;
$purple-400: #ab47bc;
$purple-500: #9c27b0;
$purple-600: #8e24aa;
$purple-700: #7b1fa2;
$purple-800: #6a1b9a;
$purple-900: #4a148c;
$purple-A100: #ea80fc;
$purple-A200: #e040fb;
$purple-A400: #d500f9;
$purple-A700: #aa00ff;

$purples: () !default;
$purples: map-merge(
  (
    '50': $purple-50,
    '100': $purple-100,
    '200': $purple-200,
    '300': $purple-300,
    '400': $purple-400,
    '500': $purple-500,
    '600': $purple-600,
    '700': $purple-700,
    '800': $purple-800,
    '900': $purple-900,
    'A100': $purple-A100,
    'A200': $purple-A200,
    'A400': $purple-A400,
    'A700': $purple-A700,
  ),
  $purples
);

$deepPurple-50: #ede7f6;
$deepPurple-100: #d1c4e9;
$deepPurple-200: #b39ddb;
$deepPurple-300: #9575cd;
$deepPurple-400: #7e57c2;
$deepPurple-500: #673ab7;
$deepPurple-600: #5e35b1;
$deepPurple-700: #512da8;
$deepPurple-800: #4527a0;
$deepPurple-900: #311b92;
$deepPurple-A100: #b388ff;
$deepPurple-A200: #7c4dff;
$deepPurple-A400: #651fff;
$deepPurple-A700: #6200ea;

$deepPurples: () !default;
$deepPurples: map-merge(
  (
    '50': $deepPurple-50,
    '100': $deepPurple-100,
    '200': $deepPurple-200,
    '300': $deepPurple-300,
    '400': $deepPurple-400,
    '500': $deepPurple-500,
    '600': $deepPurple-600,
    '700': $deepPurple-700,
    '800': $deepPurple-800,
    '900': $deepPurple-900,
    'A100': $deepPurple-A100,
    'A200': $deepPurple-A200,
    'A400': $deepPurple-A400,
    'A700': $deepPurple-A700,
  ),
  $deepPurples
);

$indigo-50: #e8eaf6;
$indigo-100: #c5cae9;
$indigo-200: #9fa8da;
$indigo-300: #7986cb;
$indigo-400: #5c6bc0;
$indigo-500: #3f51b5;
$indigo-600: #3949ab;
$indigo-700: #303f9f;
$indigo-800: #283593;
$indigo-900: #1a237e;
$indigo-A100: #8c9eff;
$indigo-A200: #536dfe;
$indigo-A400: #3d5afe;
$indigo-A700: #304ffe;

$indigos: () !default;
$indigos: map-merge(
  (
    '50': $indigo-50,
    '100': $indigo-100,
    '200': $indigo-200,
    '300': $indigo-300,
    '400': $indigo-400,
    '500': $indigo-500,
    '600': $indigo-600,
    '700': $indigo-700,
    '800': $indigo-800,
    '900': $indigo-900,
    'A100': $indigo-A100,
    'A200': $indigo-A200,
    'A400': $indigo-A400,
    'A700': $indigo-A700,
  ),
  $indigos
);

$blue-50: #e3f2fd;
$blue-100: #bbdefb;
$blue-200: #90caf9;
$blue-300: #64b5f6;
$blue-400: #42a5f5;
$blue-500: #2196f3;
$blue-600: #1e88e5;
$blue-700: #1976d2;
$blue-800: #1565c0;
$blue-900: #0d47a1;
$blue-A100: #82b1ff;
$blue-A200: #448aff;
$blue-A400: #2979ff;
$blue-A700: #2962ff;

$blues: () !default;
$blues: map-merge(
  (
    '50': $blue-50,
    '100': $blue-100,
    '200': $blue-200,
    '300': $blue-300,
    '400': $blue-400,
    '500': $blue-500,
    '600': $blue-600,
    '700': $blue-700,
    '800': $blue-800,
    '900': $blue-900,
    'A100': $blue-A100,
    'A200': $blue-A200,
    'A400': $blue-A400,
    'A700': $blue-A700,
  ),
  $blues
);

$lightBlue-50: #e1f5fe;
$lightBlue-100: #b3e5fc;
$lightBlue-200: #81d4fa;
$lightBlue-300: #4fc3f7;
$lightBlue-400: #29b6f6;
$lightBlue-500: #03a9f4;
$lightBlue-600: #039be5;
$lightBlue-700: #0288d1;
$lightBlue-800: #0277bd;
$lightBlue-900: #01579b;
$lightBlue-A100: #80d8ff;
$lightBlue-A200: #40c4ff;
$lightBlue-A400: #00b0ff;
$lightBlue-A700: #0091ea;

$lightBlues: () !default;
$lightBlues: map-merge(
  (
    '50': $lightBlue-50,
    '100': $lightBlue-100,
    '200': $lightBlue-200,
    '300': $lightBlue-300,
    '400': $lightBlue-400,
    '500': $lightBlue-500,
    '600': $lightBlue-600,
    '700': $lightBlue-700,
    '800': $lightBlue-800,
    '900': $lightBlue-900,
    'A100': $lightBlue-A100,
    'A200': $lightBlue-A200,
    'A400': $lightBlue-A400,
    'A700': $lightBlue-A700,
  ),
  $lightBlues
);

$cyan-50: #e0f7fa;
$cyan-100: #b2ebf2;
$cyan-200: #80deea;
$cyan-300: #4dd0e1;
$cyan-400: #26c6da;
$cyan-500: #00bcd4;
$cyan-600: #00acc1;
$cyan-700: #0097a7;
$cyan-800: #00838f;
$cyan-900: #006064;
$cyan-A100: #84ffff;
$cyan-A200: #18ffff;
$cyan-A400: #00e5ff;
$cyan-A700: #00b8d4;

$cyans: () !default;
$cyans: map-merge(
  (
    '50': $cyan-50,
    '100': $cyan-100,
    '200': $cyan-200,
    '300': $cyan-300,
    '400': $cyan-400,
    '500': $cyan-500,
    '600': $cyan-600,
    '700': $cyan-700,
    '800': $cyan-800,
    '900': $cyan-900,
    'A100': $cyan-A100,
    'A200': $cyan-A200,
    'A400': $cyan-A400,
    'A700': $cyan-A700,
  ),
  $cyans
);

$teal-50: #e0f2f1;
$teal-100: #b2dfdb;
$teal-200: #80cbc4;
$teal-300: #4db6ac;
$teal-400: #26a69a;
$teal-500: #009688;
$teal-600: #00897b;
$teal-700: #00796b;
$teal-800: #00695c;
$teal-900: #004d40;
$teal-A100: #a7ffeb;
$teal-A200: #64ffda;
$teal-A400: #1de9b6;
$teal-A700: #00bfa5;

$teals: () !default;
$teals: map-merge(
  (
    '50': $teal-50,
    '100': $teal-100,
    '200': $teal-200,
    '300': $teal-300,
    '400': $teal-400,
    '500': $teal-500,
    '600': $teal-600,
    '700': $teal-700,
    '800': $teal-800,
    '900': $teal-900,
    'A100': $teal-A100,
    'A200': $teal-A200,
    'A400': $teal-A400,
    'A700': $teal-A700,
  ),
  $teals
);

$green-50: #e8f5e9;
$green-100: #c8e6c9;
$green-200: #a5d6a7;
$green-300: #81c784;
$green-400: #66bb6a;
$green-500: #4caf50;
$green-600: #43a047;
$green-700: #388e3c;
$green-800: #2e7d32;
$green-900: #1b5e20;
$green-A100: #b9f6ca;
$green-A200: #69f0ae;
$green-A400: #00e676;
$green-A700: #00c853;

$greens: () !default;
$greens: map-merge(
  (
    '50': $green-50,
    '100': $green-100,
    '200': $green-200,
    '300': $green-300,
    '400': $green-400,
    '500': $green-500,
    '600': $green-600,
    '700': $green-700,
    '800': $green-800,
    '900': $green-900,
    'A100': $green-A100,
    'A200': $green-A200,
    'A400': $green-A400,
    'A700': $green-A700,
  ),
  $greens
);

$lightGreen-50: #f1f8e9;
$lightGreen-100: #dcedc8;
$lightGreen-200: #c5e1a5;
$lightGreen-300: #aed581;
$lightGreen-400: #9ccc65;
$lightGreen-500: #8bc34a;
$lightGreen-600: #7cb342;
$lightGreen-700: #689f38;
$lightGreen-800: #558b2f;
$lightGreen-900: #33691e;
$lightGreen-A100: #ccff90;
$lightGreen-A200: #b2ff59;
$lightGreen-A400: #76ff03;
$lightGreen-A700: #64dd17;

$lightGreens: () !default;
$lightGreens: map-merge(
  (
    '50': $lightGreen-50,
    '100': $lightGreen-100,
    '200': $lightGreen-200,
    '300': $lightGreen-300,
    '400': $lightGreen-400,
    '500': $lightGreen-500,
    '600': $lightGreen-600,
    '700': $lightGreen-700,
    '800': $lightGreen-800,
    '900': $lightGreen-900,
    'A100': $lightGreen-A100,
    'A200': $lightGreen-A200,
    'A400': $lightGreen-A400,
    'A700': $lightGreen-A700,
  ),
  $lightGreens
);

$lime-50: #f9fbe7;
$lime-100: #f0f4c3;
$lime-200: #e6ee9c;
$lime-300: #dce775;
$lime-400: #d4e157;
$lime-500: #cddc39;
$lime-600: #c0ca33;
$lime-700: #afb42b;
$lime-800: #9e9d24;
$lime-900: #827717;
$lime-A100: #f4ff81;
$lime-A200: #eeff41;
$lime-A400: #c6ff00;
$lime-A700: #aeea00;

$limes: () !default;
$limes: map-merge(
  (
    '50': $lime-50,
    '100': $lime-100,
    '200': $lime-200,
    '300': $lime-300,
    '400': $lime-400,
    '500': $lime-500,
    '600': $lime-600,
    '700': $lime-700,
    '800': $lime-800,
    '900': $lime-900,
    'A100': $lime-A100,
    'A200': $lime-A200,
    'A400': $lime-A400,
    'A700': $lime-A700,
  ),
  $limes
);

$yellow-50: #fffde7;
$yellow-100: #fff9c4;
$yellow-200: #fff59d;
$yellow-300: #fff176;
$yellow-400: #ffee58;
$yellow-500: #ffeb3b;
$yellow-600: #fdd835;
$yellow-700: #fbc02d;
$yellow-800: #f9a825;
$yellow-900: #f57f17;
$yellow-A100: #ffff8d;
$yellow-A200: #ffff00;
$yellow-A400: #ffea00;
$yellow-A700: #ffd600;

$yellows: () !default;
$yellows: map-merge(
  (
    '50': $yellow-50,
    '100': $yellow-100,
    '200': $yellow-200,
    '300': $yellow-300,
    '400': $yellow-400,
    '500': $yellow-500,
    '600': $yellow-600,
    '700': $yellow-700,
    '800': $yellow-800,
    '900': $yellow-900,
    'A100': $yellow-A100,
    'A200': $yellow-A200,
    'A400': $yellow-A400,
    'A700': $yellow-A700,
  ),
  $yellows
);

$amber-50: #fff8e1;
$amber-100: #ffecb3;
$amber-200: #ffe082;
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;
$amber-A100: #ffe57f;
$amber-A200: #ffd740;
$amber-A400: #ffc400;
$amber-A700: #ffab00;

$ambers: () !default;
$ambers: map-merge(
  (
    '50': $amber-50,
    '100': $amber-100,
    '200': $amber-200,
    '300': $amber-300,
    '400': $amber-400,
    '500': $amber-500,
    '600': $amber-600,
    '700': $amber-700,
    '800': $amber-800,
    '900': $amber-900,
    'A100': $amber-A100,
    'A200': $amber-A200,
    'A400': $amber-A400,
    'A700': $amber-A700,
  ),
  $ambers
);

$orange-50: #fff3e0;
$orange-100: #ffe0b2;
$orange-200: #ffcc80;
$orange-300: #ffb74d;
$orange-400: #ffa726;
$orange-500: #ff9800;
$orange-600: #fb8c00;
$orange-700: #f57c00;
$orange-800: #ef6c00;
$orange-900: #e65100;
$orange-A100: #ffd180;
$orange-A200: #ffab40;
$orange-A400: #ff9100;
$orange-A700: #ff6d00;

$oranges: () !default;
$oranges: map-merge(
  (
    '50': $orange-50,
    '100': $orange-100,
    '200': $orange-200,
    '300': $orange-300,
    '400': $orange-400,
    '500': $orange-500,
    '600': $orange-600,
    '700': $orange-700,
    '800': $orange-800,
    '900': $orange-900,
    'A100': $orange-A100,
    'A200': $orange-A200,
    'A400': $orange-A400,
    'A700': $orange-A700,
  ),
  $oranges
);

$deepOrange-50: #fbe9e7;
$deepOrange-100: #ffccbc;
$deepOrange-200: #ffab91;
$deepOrange-300: #ff8a65;
$deepOrange-400: #ff7043;
$deepOrange-500: #ff5722;
$deepOrange-600: #f4511e;
$deepOrange-700: #e64a19;
$deepOrange-800: #d84315;
$deepOrange-900: #bf360c;
$deepOrange-A100: #ff9e80;
$deepOrange-A200: #ff6e40;
$deepOrange-A400: #ff3d00;
$deepOrange-A700: #dd2c00;

$deepOranges: () !default;
$deepOranges: map-merge(
  (
    '50': $deepOrange-50,
    '100': $deepOrange-100,
    '200': $deepOrange-200,
    '300': $deepOrange-300,
    '400': $deepOrange-400,
    '500': $deepOrange-500,
    '600': $deepOrange-600,
    '700': $deepOrange-700,
    '800': $deepOrange-800,
    '900': $deepOrange-900,
    'A100': $deepOrange-A100,
    'A200': $deepOrange-A200,
    'A400': $deepOrange-A400,
    'A700': $deepOrange-A700,
  ),
  $deepOranges
);

$red-50: #ffebee;
$red-100: #ffcdd2;
$red-200: #ef9a9a;
$red-300: #e57373;
$red-400: #ef5350;
$red-500: #f44336;
$red-600: #e53935;
$red-700: #d32f2f;
$red-800: #c62828;
$red-900: #b71c1c;
$red-A100: #ff8a80;
$red-A200: #ff5252;
$red-A400: #ff1744;
$red-A700: #d50000;

$reds: () !default;
$reds: map-merge(
  (
    '50': $red-50,
    '100': $red-100,
    '200': $red-200,
    '300': $red-300,
    '400': $red-400,
    '500': $red-500,
    '600': $red-600,
    '700': $red-700,
    '800': $red-800,
    '900': $red-900,
    'A100': $red-A100,
    'A200': $red-A200,
    'A400': $red-A400,
    'A700': $red-A700,
  ),
  $reds
);

$brown-50: #efebe9;
$brown-100: #d7ccc8;
$brown-200: #bcaaa4;
$brown-300: #a1887f;
$brown-400: #8d6e63;
$brown-500: #795548;
$brown-600: #6d4c41;
$brown-700: #5d4037;
$brown-800: #4e342e;
$brown-900: #3e2723;
$brown-A100: #d7ccc8;
$brown-A200: #bcaaa4;
$brown-A400: #8d6e63;
$brown-A700: #5d4037;

$browns: () !default;
$browns: map-merge(
  (
    '50': $brown-50,
    '100': $brown-100,
    '200': $brown-200,
    '300': $brown-300,
    '400': $brown-400,
    '500': $brown-500,
    '600': $brown-600,
    '700': $brown-700,
    '800': $brown-800,
    '900': $brown-900,
    'A100': $brown-A100,
    'A200': $brown-A200,
    'A400': $brown-A400,
    'A700': $brown-A700,
  ),
  $browns
);

$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray-150: #f1f1f1;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$gray-A100: #d5d5d5;
$gray-A200: #aaaaaa;
$gray-A400: #303030;
$gray-A700: #616161;

$grays: () !default;
$grays: map-merge(
  (
    '50': $gray-50,
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
    'A100': $gray-A100,
    'A200': $gray-A200,
    'A400': $gray-A400,
    'A700': $gray-A700,
  ),
  $grays
);

$subnav-standard: map-get($grays, '200');
$subnav-active: map-get($grays, 'A100');
$subnav-hover: map-get($grays, '400');

$inputs-standard: #3d9bde;
$inputs-active: #3d9bde;
$inputs-hover: #427790;

$outputs-standard: #f5ed0a;
$outputs-active: #ded60a;
$outputs-hover: #dbd20b;
