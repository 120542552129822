.wizard-page {
  .sidebar__left-side {
    min-width: 275px !important;
    max-width: 275px !important;
  }

  .sidebar__right-side {
    width: calc(100% - 275px) !important;
    box-shadow: -2px 0 10px 0 #a3a3a3;
  }
}

.wizard-documentation-button__sidebar {
  font-size: 0.9rem;
}

.access-request-submission-wizard {
  display: flex;
  justify-content: space-evenly;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 5rem;

  .wizard-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    max-width: 1000px;
  }

  .form-validation-error {
    padding-top: 2px;
    padding-left: 15px;
    font-size: 12px;
    color: $red;
    position: absolute;
  }

  input.form-control {
    border-radius: 1.5em;
    width: 450px;
  }

  .form-input-select {
    width: 450px;
  }
  /** Wizard styles **/

  .nav-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;
  }

  .btn-none:hover {
    text-decoration: underline;
  }

  .fa-chevron-left {
    padding-right: 0.5rem;
  }

  .fa-chevron-right {
    padding-left: 0.5rem;
  }

  .dropdown-line {
    width: 20%;
    margin: 3rem 0;
  }

  .box-header {
    color: $dark-black;
    font-size: 125%;
    margin-bottom: 2rem;
  }

  .access-units {
    margin: 1rem auto;
    @extend %blue-top-border;
    @extend %grid-shadow;
    padding: 2rem;

    ul {
      margin-top: 1rem;
      padding-left: 1rem;
    }
  }

  //custom checkboxes
  .checkbox-container {
    position: relative;
    padding-left: 25px;
    display: block;
  }

  .checkbox-container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .form-check-label {
    margin-bottom: 1rem;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid $gray-700;
  }

  .checkbox-container input:checked ~ .checkmark {
    border: 2px solid $gray-700;
    background-color: $gray-700;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  .checkbox-container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  /**
   * Wizard
   */

  .wizard-header {
    font-size: 50px;
    text-align: center;
    color: $primary;
    margin-top: 2rem;
  }

  .wizard-sub-header {
    font-size: 16px;
    text-align: center;
    padding: 1.25rem 0;
    color: $dark-black;
  }

  .page-sub-header {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 24px;
    text-align: center;
    color: $primary;
  }

  .applicant-header {
    display: flex;
    justify-content: space-between;
  }

  .center {
    text-align: center;
    align-self: center;
  }

  .options-row {
    display: flex;
    justify-content: space-evenly;
  }

  .options-button {
    width: 275px;
  }

  .summary-edit-btn {
    margin-right: 1.5rem;
    margin-bottom: 6px;
  }

  .form-text {
    width: 450px;
    margin-top: 10px;
  }

  .form-label {
    font-size: 0.9rem;
  }

  /**
  * User Form
  **/

  .user-form {
    .form-check {
      padding-left: 1.5rem;
      font-size: 0.9rem;
      width: 450px;
    }
  }

  /**
   * Manual Application Form
   **/

  .manual-application {
    .page-sub-header {
      padding-top: 4rem;
    }

    .agreement-download {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin: 1rem auto;
    }

    .download-button {
      margin: 1rem auto 0.5rem auto;
    }

    .fa-lg {
      font-size: 1.1em;
      padding-right: 0.25rem;
    }

    .dropzone-form-upload {
      border-radius: 0;
      padding: 4rem;
    }
  }

  /**
   * Summary
   **/

  .summary-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-left: 5rem;

    .form-group {
      margin-left: 5.75rem;
    }

    .form-check-label {
      margin-left: 3.75rem;
    }

    .summary-form-heading {
      color: $primary;
      margin: 1.25rem 0 0.75rem -0.75rem;
    }

    .summary-form-label {
      width: auto !important;
      font-size: 1em !important;
      font-weight: bold;
      padding-left: 5.25rem;
    }

    .save-progress-btn,
    .submit-draft-btn {
      width: 400px;
    }
  }

  .wizard-card {
    @include card(true, true);
    @extend %blue-top-border;
    padding: 30px;
    flex-flow: column;
    cursor: pointer;

    &.disabled {
      cursor: unset !important;
      margin-top: 2.7rem !important;
      margin-bottom: 0 !important;

      &:hover {
        box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25) !important;
      }
    }

    &.active {
      box-shadow: 0px 2px 5px 1px rgba(24, 116, 220, 0.75);

      &:hover {
        box-shadow: 0px 15px 25px 2px rgba(24, 116, 220, 0.25),
          0px 6px 30px 5px rgba(24, 116, 220, 0.25), 0px 10px 10px -6px rgba(24, 116, 220, 0.5);
      }
    }

    .wizard-card__image {
      height: 134px;
      margin: 54px auto;
      display: flex;
    }

    .title-container {
      margin: 1rem;
      min-height: 36px;
      display: flex;
    }

    .title {
      margin: auto;
      text-align: center;
      font-size: 24px;
    }

    .subtitle-container {
      margin: 1.5rem;
      min-height: 81px;
      display: flex;
    }

    .subtitle {
      text-align: center;
      font-size: 18px;
      margin: auto;
    }

    .btn {
      margin: 1.5rem auto;
      display: flex;
    }

    ul {
      min-height: 265px;
      list-style-type: none;

      li::before {
        font-family: 'Font Awesome 5 Free';
        margin: 0 10px 0 -25px;
        font-weight: 900;
        content: '\f055';
        color: $primary;
      }

      li.con::before {
        content: '\f146';
        color: $danger;
        margin: 0 10px 0 -24px;
      }

      li.warn::before {
        content: '\f071';
        color: $warning;
        margin: 0 10px 0 -28px;
      }

      li.okay::before {
        content: '\f058';
        color: $success;
        margin: 0 10px 0 -28px;
      }
    }

    .wizard-card__info {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: stretch;
      align-items: center;
      flex-grow: 1;
    }
  }
}

/**
 * Side-Nav
 **/
.access-request-submission-wizard-side-nav {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  margin-left: 1.5rem;
  margin-bottom: 1.75rem;
  font-size: 0.9rem;
  position: sticky;
  top: 1.75rem;

  /* Items in side navigation for wizard  */
  .bookmark {
    color: $dark-black !important;
    padding-left: 15px;
    margin-bottom: 0.75rem;
    line-height: 1.75rem;
  }

  .bookmark.selected {
    border-left: 3px solid $primary;
    color: $dark-black;
    font-weight: bold;
    padding-left: 12px;
    &:hover {
      text-decoration: none;
    }
  }

  .save-draft-btn {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-top: 2rem;
  }
}
