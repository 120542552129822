.access-unit-badge {
  margin: 2px;
  padding: 2px;
  border: 2px solid;
  border-radius: 5px;
  font-size: 14px;
  line-height: initial;

  &.status-approved {
    background-color: scale-color(#2f7600, $lightness: 95%);
    border-color: #2f7600;
    color: #2f7600;
  }

  &.status-rejected {
    background-color: scale-color(#b4062d, $lightness: 95%);
    border-color: #b4062d;
    color: #b4062d;
  }

  &.status-pending {
    background-color: scale-color(#df9105, $lightness: 95%);
    border-color: #df9105;
    color: #df9105;
  }

  &.status-closed {
    border-color: black;
    color: black;
  }

  &.status-revoked {
    background-color: scale-color(#888888, $lightness: 95%);
    border-color: #888888;
    color: #888888;
  }

  &.status-undone {
    border-color: black;
    color: black;
  }

  &.status- {
    background-color: scale-color(#000, $lightness: 95%);
    border-color: #000;
    color: #000;
  }
}
