.summary-table {
  border-top: 4px solid white;
  border-bottom: 2px solid white;
  background-color: white;
  color: #333;
  padding-left: 6px;

  .flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
    justify-content: space-around;
    height: 50%;
  }

  .flex-item-1 {
    border: 2px solid rgb(205, 173, 220);
    background: rgba(205, 173, 220, 0.1);
    border-radius: 10px;
    margin: 0px 2px 2px 0px;
    text-align: center;
    flex: 1 0 0px;
    height: auto;
  }

  .flex-item-2 {
    border: 2px solid rgb(255, 227, 102);
    background: rgba(255, 227, 102, 0.1);
    border-radius: 10px;
    margin: 0px 0px 2px 2px;
    text-align: center;
    flex: 1 0 0px;
    height: auto;
  }

  .flex-item-3 {
    border: 2px solid rgb(217, 233, 106);
    background: rgba(217, 233, 106, 0.1);
    border-radius: 10px;
    margin: 2px 2px 0px 0px;
    text-align: center;
    flex: 1 0 0px;
    height: auto;
  }

  .flex-item-4 {
    border: 2px solid rgb(162, 227, 239);
    background: rgba(162, 227, 239, 0.1);
    border-radius: 10px;
    margin: 2px 0px 0px 2px;
    text-align: center;
    flex: 1 0 0px;
    height: auto;
  }

  .summary-icon {
    margin-top: 10px;
  }

  .summary-value {
    font-size: 20px;
    font-weight: bold;
  }

  .summary-type {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
