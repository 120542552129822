.dashboard-page {
  .dashboard-card {
    @include card($enable-shadow: false);
    @extend %grid-shadow;
    @extend %navy-top-border;
    height: 100%;

    .btn {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .dashboard-card-image {
    max-height: 25px;
    max-width: 25px;
    margin-right: 5px;
  }

  .waiting-on-signatures-progress {
    .progress {
      height: 7px;
      background-color: #fbfdff;
    }
    line-height: 14px;

    .progress-label {
      font-weight: 500;
    }
  }

  .expiring-in-text {
    font-size: 8px;
    font-weight: 500;
  }

  .signing-status__badge {
    padding: 7px 15px;
  }
}

.signing-status__popover {
  min-width: 400px;
}

.signing-status__applicant-email {
  font-size: 12px;
  color: $gray-500;
}
