.markdown-component {
  blockquote {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    background: $container-blue;

    @include make-container($gutter: 0px);

    strong {
      color: #333;
      font-weight: 600;
    }
  }

  a {
    @include link-like();
  }

  h2,
  h3,
  h4,
  h5,
  p {
    line-height: 26px;
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: 2rem;
  }

  h1 {
    margin-top: 0px;
    font-size: 24px;
    font-weight: 600;
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
  }
  h3 {
    font-size: 20px;
    font-weight: 400;
  }
  h4 {
    font-size: 16px;
    font-weight: 300;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
  }

  > *:not(blockquote) {
    @include make-container();
    @include make-container-max-widths();
  }

  > ol,
  > ul {
    padding-left: 35px;
  }
}
