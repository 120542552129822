.login-redirect-card {
  @include card();
  margin: auto;
  margin-top: 3rem;
  width: calc(50%);

  .content-wrap {
    width: 100%;
    height: 100%;
    display: block;
    padding: 1.55rem;
  }

  .login-notification-title {
    color: $primary-20;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    text-align: center;
  }

  .redirect-text {
    text-align: center;
  }
}
