.dropzone-form {
  .dropzone-form-container {
    display: flex;
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    .dropzone-form-container {
      min-width: 510px;
    }
  }

  .dropzone-buttons-container--inline {
    width: 200px;
    margin-left: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    button {
      width: 100%;
    }
  }
  .dropzone-ctl-btn {
    height: initial;
    white-space: nowrap;
    span.fa {
      font-size: 1.2rem;
    }
    span {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
}
