.tool-launcher-page {
  height: 100%;
  padding: 0px;

  .spinner {
    margin: 20px auto;
    text-align: center;
  }

  .other-tools-title {
    line-height: 26px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .tool-header {
    padding-top: 1rem;
    padding-bottom: 1rem;

    img.card-img,
    .tool-image,
    .tool-image img {
      width: 130px;
      height: 130px;

      @include media-breakpoint-up(md) {
        width: 155px;
        height: 155px;
      }
    }

    .tool-title {
      font-size: 28px;
      font-weight: bold;

      @include media-breakpoint-up(md) {
        font-size: 40px;
      }
    }

    .tool-subtitle {
      font-size: 20px;
      font-weight: normal;

      @include media-breakpoint-up(md) {
        font-size: 28px;
      }
    }

    .tool-buttons {
      display: flex;
      @include media-breakpoint-down(sm) {
        width: 100%;
        flex-direction: column;
        align-items: center;

        .btn {
          margin-bottom: 1rem;
        }
      }

      @include media-breakpoint-up(md) {
        .btn {
          margin-right: 1rem;
        }
      }
    }
  }
}
