.data-browser-grid__tooltip {
  color: $white !important;
}

.data-browser-grid {
  /**
   * AG-GRID
   **/

  /**
   * Reduce the padding on cells
   **/
  .ag-theme-material .ag-cell {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ag-theme-material .ag-overlay-loading-wrapper {
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .ag-theme-material .ag-header-cell,
  .ag-theme-material .ag-header-group-cell {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ag-header {
    font-family: 'St. Jude Sans';
  }

  .ag-theme-material .ag-header-cell-label {
    width: 100%;
  }

  .ag-theme-material .ag-header-cell-label .ag-header-cell-text {
    text-overflow: clip;
  }

  .ag-header-cell-text {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-family: 'St. Jude Sans';
  }

  .ag-header-row {
    border-top: 1px solid #ccc;
  }

  .ag-theme-material {
    font-size: 14px;
    font-family: 'St. Jude Sans' !important;
  }

  .ag-icon-checkbox-indeterminate,
  .ag-icon-checkbox-checked {
    color: $primary;
  }

  .ag-cell {
    text-overflow: clip;
  }

  .ag-header-cell-resize:hover {
    border-right: 4px solid lightgray;
  }

  .ag-icon-checkbox-unchecked {
    background-size: 16px 16px !important;
  }

  .ag-row-odd {
    background: rgba(0, 0, 0, 0.03);
  }

  .ag-body-viewport-wrapper.ag-layout-normal {
    height: 95%;
  }

  .ag-full-width-viewport-wrapper {
    height: 90%;
  }

  .ag-theme-material .ag-cell-data-changed {
    background-color: $primary-80 !important;
    font-weight: bold;
    transition: 0.5s;
  }

  .ag-cell-data-changed-animation {
    background-color: $primary-80 !important;
  }

  .grid-container {
    height: 580px;
    width: 100%;
  }

  /**
   * Grid Cell
   **/

  .cell-wrap-text > .ag-react-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }

  /**
   * Pagination
   *
   **/

  .pagination {
    padding-right: 10px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }

  .pagination button {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    background-color: white;
  }

  .pagination button:hover {
    background-color: #ddd;
  }

  .pagination-row {
    font-size: 14px;
    line-height: normal;
    padding: 15px 5px 15px 5px;
  }

  .page-text {
    line-height: 36px;
    margin-left: 10px;
  }

  .page-num {
    user-select: none;
    font-weight: bold;
  }

  /**
   * Loading Bar
   **/

  .loading-bar {
    position: relative !important;

    & > div {
      background-color: $primary !important;
    }
  }
}
