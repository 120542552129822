@mixin link-like($font-weight: normal) {
  text-decoration: none;
  font-weight: $font-weight;
  cursor: pointer;

  &:not(.btn) {
    color: $primary;

    &:hover {
      color: $primary-hover;
      text-decoration: underline;
    }

    &:active {
      color: $primary-active;
    }
  }
}
