.wizard-documentation-button {
  line-height: 27px;
  cursor: pointer;

  &.sidebar {
    float: right;
    padding: 0 0.5rem 0 0.5rem;
  }

  &.full-content {
    align-self: flex-end;
    padding-top: 1.5rem;
    padding-right: 2rem;
  }

  &__help-icon {
    background-color: $primary;
    color: $white;
    font-size: 18px;
    margin-left: 5px;
    font-weight: bold;

    width: 28px;
    height: 28px;
    display: inline-block;
    border-radius: 100%;

    padding: 0px !important;
    line-height: 0.75rem !important;
  }
}
