.admin-tools-list {
  .tools-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tools-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .tool-card-actions {
    display: flex;
    align-items: center;
  }

  .tool-edit-buttons {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin-left: 2rem;

    .btn {
      width: 100%;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}
