/**
* React Tabs CSS
**/

.data-browser-page {
  .data-browser-container {
    @extend %grid-shadow;
  }

  .data-browser-tab.nav-item .nav-link {
    border: none;
    border-radius: 0;
  }

  .tab-sub-text {
    font-size: 13px;
  }

  .data-browser-tab-list {
    width: 100%;

    &.nav-tabs {
      border: none !important;
    }
  }

  .data-browser-subtab-list {
    &.nav-tabs {
      border: none !important;
    }
  }

  .top-row {
    font-size: 14px;
    line-height: normal;
    padding: 15px 5px 15px 5px;

    .col,
    .col-auto {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    @include media-breakpoint-down(xl) {
      .databrowser-filter-group,
      .top-row-right-col {
        width: 100%;
      }
    }
  }

  /**
   * Search Form CSS
   *
   **/
  .databrowser-filter-group {
    .input-group {
      flex-wrap: nowrap;
    }

    .form-floating {
      width: 100%;

      label {
        font-size: 16px;
      }
    }
  }

  .filter-box {
    margin: 0 1.5rem;

    .filter-box-search-input {
      min-width: 90px;
    }

    .input-group-text {
      background-color: $white;
      height: 100%;
      border-left: none;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .filter-box input:focus {
    border-color: $primary;
    box-shadow: 0 0 5px rgba(24, 116, 220, 0.3);
  }

  .data-browser-container.disabled .data-browser-tab-list,
  .data-browser-container.disabled .data-browser-subtab-list,
  .data-browser-container.disabled .tab-underline,
  .data-browser-container.disabled .top-row,
  .data-browser-container.disabled .pagination-row {
    opacity: 40%;
  }

  .data-browser-tab {
    cursor: pointer;
    width: 25%;
    text-align: center;
    font-size: 19px;
    border-bottom: 1px solid #ccc;
    color: #333;
    transition-duration: 0.3s;
    background: white;
  }

  .subtab-button-group {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .btn-group-databrowser-subtab {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    min-height: 58px;
    width: 100%;

    .btn {
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
    }

    &.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
    &.btn-group > .btn-group:not(:last-child) > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.btn-group > .btn:nth-child(n + 3),
    &.btn-group > :not(.btn-check) + .btn,
    &.btn-group-databrowser-subtab.btn-group > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    label {
      margin-bottom: 0;
    }
  }

  .data-browser-subtab {
    cursor: pointer;
    text-align: center;
    font-size: 19px;
    border-bottom: 1px solid #ccc;
    color: #333;
    transition-duration: 0.3s;
    background: white;
  }

  .data-browser-tab .nav-link {
    height: 100%;
    color: black;
    padding: 12px;
  }

  .data-browser-tab .nav-link.active {
    border-color: $primary;
    color: $primary;
    position: relative;
    transition-delay: 0.15s;
    transition-duration: 0.3s;
    background: $primary-80;
  }

  .data-browser-tab:focus {
    outline: none;
  }

  .data-browser-tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }

  .data-browser-tab-panel {
    display: none;
  }

  .data-browser-tab-panel--selected {
    display: block;
  }

  .tab-underline {
    height: 0.25rem;
    width: 25%;
    margin: 0;
    background: $primary;
    border: none;
    transition: 0.15s ease-in-out;
    position: relative;
    opacity: 100%;
  }

  .tab-underline:after,
  .tab-underline:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .tab-underline:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: $primary;
    border-width: 3px;
    margin-left: -3px;
  }

  .tab-underline:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: $primary;
    border-width: 9px;
    margin-left: -9px;
  }

  .tab-underline.diseases {
    margin-left: 0;
  }

  .tab-underline.publications {
    margin-left: 25%;
  }

  .tab-underline.cohorts {
    margin-left: 50%;
  }

  .tab-underline.samples {
    margin-left: 75%;
  }

  /**
   * Bottom Row
   *
   **/

  .bottom-list {
    display: inline;
    font-size: 16px;
  }

  .bottom-row {
    padding-top: 4rem;
    list-style: none;
    float: right;
  }

  /* Badges */

  .type-badge {
    border: 1px solid lightgray;
    background: lightgray;
    color: gray;
    margin-right: 5px;
    padding: 2px 3px 2px 3px;
    border-radius: 3px;
  }

  .type-badge-active {
    border: 1px solid $primary-80;
    background: $primary-80;
    color: black;
    margin-right: 5px;
    padding: 2px 3px 2px 3px;
    border-radius: 3px;
  }

  /**
   * Tooltip
   **/

  .spinner {
    color: #333;
    padding-bottom: 20px;
  }

  .center-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.saved-file-set-message {
  font-size: 15px;
  line-height: 24px;

  .pecan-icon {
    max-width: 50px;
    max-height: 50px;
    margin-right: 1rem;
  }
}
