.filter-box {
  &.match-width {
    .fa.fa-search {
      margin-left: 0.16rem;
      margin-right: 0.2rem;
    }
  }

  // Pacman styles are copied from react-spinkit, but overriden to make the loader smaller
  .pacman > div:first-of-type {
    width: 0px;
    height: 0px;
    border-right: 11px solid transparent;
    border-top: 11px solid black;
    border-left: 11px solid black;
    border-bottom: 11px solid black;
    border-radius: 25px;
    -webkit-animation: rotate_pacman_half_up 0.5s 0s infinite;
    animation: rotate_pacman_half_up 0.5s 0s infinite;
    position: relative;
    left: -7px;
  }
  .pacman > div:nth-child(2) {
    width: 0px;
    height: 0px;
    border-right: 11px solid transparent;
    border-top: 11px solid black;
    border-left: 11px solid black;
    border-bottom: 11px solid black;
    border-radius: 25px;
    -webkit-animation: rotate_pacman_half_down 0.5s 0s infinite;
    animation: rotate_pacman_half_down 0.5s 0s infinite;
    margin-top: -22px;
    position: relative;
    left: -7px;
  }
  .pacman > div:nth-child(3),
  .pacman > div:nth-child(4),
  .pacman > div:nth-child(5),
  .pacman > div:nth-child(6) {
    background-color: black;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    width: 5px;
    height: 5px;
    position: absolute;
    -webkit-transform: translate(0, -6.25px);
    -ms-transform: translate(0, -6.25px);
    transform: translate(0, -6.25px);
    top: 13px;
    left: 90px;
  }

  .pacman > div:nth-child(3) {
    -webkit-animation: pacman-balls 1.5s 1s infinite linear;
    animation: pacman-balls 1.5s 1s infinite linear;
  }
  .pacman > div:nth-child(4) {
    -webkit-animation: pacman-balls 1.5s 0.5s infinite linear;
    animation: pacman-balls 1.5s 0.5s infinite linear;
  }
  .pacman > div:nth-child(5) {
    -webkit-animation: pacman-balls 1.5s 0s infinite linear;
    animation: pacman-balls 1.5s 0s infinite linear;
  }
}
