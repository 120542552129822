.sidebar__wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;

  .sidebar__left-side {
    min-width: 230px;
    max-width: 230px;
    background-color: $gray-150;
  }
  
  .sidebar__right-side {
    width: calc(100% - 230px);
  }
}
