.sjc-omnibar {
  .login-spinner {
    display: flex;
    margin: 10px;
    color: $white;
  }

  .hover-none:hover {
    text-decoration: none !important;
    cursor: default !important;
  }

  .navbar-nav {
    .fa {
      font-size: 1rem;
    }
  }

  .react-gravatar {
    border-radius: 4px;
  }
}
