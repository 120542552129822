.feature-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  margin: 15px;
  background-color: $gray-150;

  .feature-title {
    color: $primary;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    max-width: 200px;
    width: 200px;
  }

  .feature-subtitle {
    color: black;
    font-size: 12px;
    text-align: center;
    max-width: 200px;
    width: 200px;
    min-height: 3em;
  }

  .feature-icon {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: white;
    margin: 10px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
}
