.admin-user-page {
  .table {
    td {
      font-size: 16px;
      line-height: 1.875;
      color: $black;
    }
  }

  .user-details {
    margin-bottom: 4rem;
  }

  .access-units {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;

    .access-units__col {
      padding: 3rem;
      border-top: 3px solid $navy;
      box-shadow: 0 2px 4px 0 #d4d4d4;

      .table {
        th {
          font-size: 11px;
          font-weight: 700;
          color: $primary;
          text-transform: uppercase;
          border-top: none;
        }
        td {
          font-size: 16px;
          line-height: 1.875;
          color: $black;
        }
        .btn {
          width: 36px;
          padding: 4px;
        }
      }
    }
  }

  .admin-user-page__form-label {
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 900;
    text-transform: uppercase;
    color: $light-black;
  }

  .access-requests {
    margin-top: 5rem;

    .new-form__row {
      align-items: center;
      justify-content: center;
      padding: 2rem;
    }
  }

  .notes {
    margin-top: 5rem;

    .new-note__row {
      align-items: center;
      justify-content: center;
      padding: 2rem;
    }
  }
}
