.electronic-data-access-request {
  h1 {
    margin-top: 2.25rem;
  }

  .request-table {
    margin: 1rem 0 2rem 0;
    @extend %blue-top-border;
    @extend %grid-shadow;
    th,
    thead {
      border-bottom: 1px $faint-black solid;
      height: 4rem;
      vertical-align: middle;
    }
    td {
      border-bottom: 1px $faint-black solid;
    }
    .first-column {
      width: 40%;
    }
  }
}
