.tool-editor {
  .form-group {
    display: flex;
  }

  .tool-editor-dropzone {
    border: dashed 1px #cccccc;
    border-radius: 3px;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    justify-content: center;

    display: flex;
    align-items: center;
    min-height: 100px;
  }

  .tool-editor-dropzone:hover {
    color: $primary;
  }

  .tool-editor-dropzone img {
    max-height: 200px;
    max-width: 200px;
  }

  .workflows-wrapper {
    flex-grow: 1;
  }

  .workflows-header {
    display: flex;
  }

  .workflows-header div {
    width: 460px;
  }

  .workflow-field {
    margin-right: 10px;
  }

  .workflows-list .workflow {
    display: flex;
    margin-bottom: 16px;
  }

  .spinner-wrapper {
    margin-top: 50px;
    text-align: center;
  }

  textarea {
    border: solid 1px #cccccc;
    border-radius: 0.5em;
    min-height: 200px;
    outline: none;
    resize: vertical;
  }

  textarea:focus {
    border-color: $primary;

    -webkit-box-shadow: 0 0 5px rgba(24, 116, 220, 0.3);
    -moz-box-shadow: 0 0 5px rgba(24, 116, 220, 0.3);
    box-shadow: 0 0 5px rgba(24, 116, 220, 0.3);
  }

  textarea {
    flex-grow: 1;
  }

  .required {
    color: $danger;
    font-weight: bold;
    margin-left: 5px;
  }
}
