.previous-requests {
  margin-top: 2rem;

  .black-text {
    color: $dark-black;
  }

  .user-requests-list {
    @extend %grid-shadow;
    @extend %navy-top-border;
  }

  .status-list {
    line-height: 22px;
  }

  .ag-react-container {
    height: 100%;
  }

  .ag-header {
    min-height: 55px !important;
  }

  .ag-header-row {
    position: static;
  }

  .ag-header-cell-label .ag-header-cell-text {
    font-family: 'St. Jude Sans', Arial, sans-serif !important;
    color: $dark-black;
    font-size: 16px;
    font-weight: bold;
  }

  .ag-cell {
    padding-left: 1.5rem;
    font-size: 16px;
  }

  .ag-row {
    min-height: 50px !important;
  }

  .ag-row-odd {
    @extend %odd-row;
  }

  .ag-row-odd:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .extension-addendum {
    width: 100%;
    color: #333;
    cursor: pointer;
  }
}
